module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DOTAZNÍK K WEBINÁRU – LIVE STREAM 02.06.2021","short_name":"Dotazník","start_url":"/","background_color":"#fff","theme_color":"#0396ff","display":"minimal-ui","icon":"src/svg/doctor-15.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"07a7534eeb7e71a1f14fc2c8e2658db0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
